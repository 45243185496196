<nav class="non-selectable">
  <div class="logo">
    <img class="logo__image" ngSrc="assets/img/app/logo-shape.svg" alt="Waytr logo" width="40" height="32" priority />
    <h1 class="logo__headline">WAYTR</h1>
  </div>
  <ul>
    <li><a (click)="scrollTo('home')">Home</a></li>
    <li><a (click)="scrollTo('solutions')">Our&nbsp;solution</a></li>
    <li><a (click)="scrollTo('features')">Features</a></li>
    <li><a (click)="scrollTo('pricing')">Pricing</a></li>
    <li><a (click)="scrollTo('contact')">Contact</a></li>
  </ul>
  <div class="cta">
    <a href="" class="cta__link">Sign&nbsp;in</a>
    <button class="cta__button" nz-button nzType="primary">Get&nbsp;started</button>
  </div>
</nav>
