import { Routes } from '@angular/router';
import { PagesEnum } from './models/enums/pages.enum';
import { HomeComponent } from './views/home/home.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full',
    data: { titleTranslationKey: 'waytr' },
  },
  {
    path: '**',
    redirectTo: PagesEnum.NOT_FOUND,
    data: { titleTranslationKey: 'page-not-found' },
    pathMatch: 'full',
  },
];
