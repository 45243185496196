import { NgOptimizedImage, isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, Inject, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { Router, RouterOutlet, RoutesRecognized } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { addAnimation, fadeAnimation, removeAnimation, routerAnimation, slideRightAnimation } from './animations';
import { AppMenuComponent } from './components/app-menu/app-menu.component';
import { ROMANIAN } from './constants';
import { stopPropagation } from './helpers';
import { TitleService } from './services';
import {
  WaytrIconsService,
  waytrAdmin,
  waytrAnalytics,
  waytrArrowDown,
  waytrArrowLeft,
  waytrArrowRight,
  waytrBowl,
  waytrBreakfast,
  waytrBurger,
  waytrBusy,
  waytrCard,
  waytrCart,
  waytrChecklist,
  waytrChicken,
  waytrClean,
  waytrCutlery,
  waytrDirectSale,
  waytrDiscount,
  waytrDrink,
  waytrEdit,
  waytrFoodMenu,
  waytrFresh,
  waytrHash,
  waytrLink,
  waytrLink2,
  waytrMushrooms,
  waytrNoodle,
  waytrNotification,
  waytrOrders,
  waytrPastry,
  waytrPizza,
  waytrPromoted,
  waytrSales,
  waytrSaltAndPepper,
  waytrSoup,
  waytrSplit,
  waytrStar,
  waytrSushi,
  waytrSweet,
  waytrSwitch,
  waytrTable,
  waytrTableManagement,
  waytrTaco,
  waytrTissue,
  waytrUpload,
  waytrWaiter,
  waytrWallet,
  waytrWeight,
} from './shared/components';
import { FeaturesComponent } from './views/features/features.component';
import { FooterComponent } from './views/footer/footer.component';
import { HomeComponent } from './views/home/home.component';
import { PricingComponent } from './views/pricing/pricing.component';
import { SolutionsComponent } from './views/solutions/solutions.component';
import { TestimonialsComponent } from './views/testimonials/testimonials.component';

@Component({
  selector: 'app-root',
  standalone: true,
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [routerAnimation, addAnimation, removeAnimation, slideRightAnimation, fadeAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    RouterOutlet,
    FormsModule,
    NzIconModule,
    NgOptimizedImage,
    HomeComponent,
    AppMenuComponent,
    SolutionsComponent,
    FeaturesComponent,
    TestimonialsComponent,
    PricingComponent,
    FooterComponent,
  ],
})
export class AppComponent implements OnInit {
  readonly #waytrIconsService = inject(WaytrIconsService);
  readonly #titleService = inject(TitleService);
  readonly #router = inject(Router);
  readonly #destroyRef = inject(DestroyRef);
  readonly #translateService = inject(TranslateService);

  protected stopPropagation = stopPropagation;

  constructor(@Inject(PLATFORM_ID) private readonly platformId: string) {
    if (isPlatformBrowser(platformId)) {
      this.#translateService.setDefaultLang(ROMANIAN);
    }

    this.#router.events.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(event => {
      if (event instanceof RoutesRecognized) {
        const data = event.state.root.firstChild?.data;
        const titleTranslationKey = data?.['titleTranslationKey'];

        if (titleTranslationKey && typeof titleTranslationKey === 'string') {
          this.#translateService
            .get(titleTranslationKey)
            .pipe(takeUntilDestroyed(this.#destroyRef))
            .subscribe((translatedTitle: string) => {
              this.#titleService.changeToNewPage(translatedTitle);
            });
        }
      }
    });

    this.#waytrIconsService.addIcons([
      waytrFresh,
      waytrBowl,
      waytrCart,
      waytrChicken,
      waytrCutlery,
      waytrMushrooms,
      waytrPastry,
      waytrPizza,
      waytrSweet,
      waytrOrders,
      waytrFoodMenu,
      waytrCart,
      waytrTableManagement,
      waytrWaiter,
      waytrDirectSale,
      waytrTable,
      waytrAnalytics,
      waytrNotification,
      waytrBusy,
      waytrStar,
      waytrSales,
      waytrHash,
      waytrLink,
      waytrCard,
      waytrUpload,
      waytrEdit,
      waytrLink2,
      waytrTaco,
      waytrNoodle,
      waytrBreakfast,
      waytrSoup,
      waytrSushi,
      waytrBurger,
      waytrDrink,
      waytrSaltAndPepper,
      waytrClean,
      waytrTissue,
      waytrArrowLeft,
      waytrArrowRight,
      waytrDiscount,
      waytrSplit,
      waytrChecklist,
      waytrArrowDown,
      waytrSwitch,
      waytrWallet,
      waytrWeight,
      waytrPromoted,
      waytrAdmin,
    ]);
  }

  ngOnInit() {
    this.#titleService.loadInitialTitle('');

    // const updatesAvailable = this.swUpdate.versionUpdates.pipe(
    //   filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
    //   map(evt => ({
    //     type: 'UPDATE_AVAILABLE',
    //     current: evt.currentVersion,
    //     available: evt.latestVersion,
    //   })),
    // );
    //
    // if (updatesAvailable) {
    //   console.log('New version available');
    //   if (
    //     window.confirm(
    //       'New version available. Do you want to update the application?',
    //     )
    //   ) {
    //     window.location.reload();
    //   }
    // }
  }
}
