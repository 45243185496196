<section class="non-selectable">
  <div class="features">
    <div class="section__title">
      <h2 class="section__title__headline">Features</h2>
      <p class="section__title__description">Core&nbsp;benefits</p>
    </div>

    <div class="features__list">
      <ul>
        <li>
          <div class="feature__icon">
            <img ngSrc="assets/img/app/clock.webp" fill />
          </div>
          <h3 class="feature__title">Faster&nbsp;Ordering</h3>
          <p class="feature__description">Reduce customer wait times by 12% with Waytr.</p>
        </li>
        <li>
          <div class="feature__icon">
            <img ngSrc="assets/img/app/waiter.webp" fill />
          </div>
          <h3 class="feature__title">Staff&nbsp;efficiency</h3>
          <p class="feature__description">Minimize waiter time spent taking orders from customers.</p>
        </li>
        <li>
          <div class="feature__icon">
            <img ngSrc="assets/img/app/pie.webp" fill />
          </div>
          <h3 class="feature__title">Presentation&nbsp;site</h3>
          <p class="feature__description">Enhance your business with an online presence.</p>
        </li>
        <li>
          <div class="feature__icon">
            <img ngSrc="assets/img/app/menu.webp" fill />
          </div>
          <h3 class="feature__title">Updated&nbsp;Menus</h3>
          <p class="feature__description">Easily update and translate menu items.</p>
        </li>
      </ul>
    </div>
  </div>

  <img class="elements" ngSrc="assets/img/app/dish.webp" width="360" height="360" />
</section>
