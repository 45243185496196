import { NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { addAnimation } from 'src/app/animations/add.animation';
import { fadeAnimation } from 'src/app/animations/fade.animation';
import { removeAnimation } from 'src/app/animations/remove.animation';

@Component({
  selector: 'app-home',
  standalone: true,
  imports: [ReactiveFormsModule, NgOptimizedImage, NzButtonModule, NzInputModule, NzFormModule, NzIconModule],
  providers: [NzNotificationService],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [addAnimation, removeAnimation, fadeAnimation],
})
export class HomeComponent {}
