export const animationInTime = 200;
export const animationInSlowTime = 1.5 * animationInTime;
export const animationInFastTime = 0.3 * animationInTime;
export const animationOutTime = 300;
export const animationOutSlowTime = 1.5 * animationOutTime;
export const animationOutFastTime = 0.3 * animationOutTime;
export const cubicBezier = 'cubic-bezier(0.3, 0.3, 0.45, 1)';
export const animationInTimings = `${animationInTime}ms ${cubicBezier}`;
export const animationInSlowTimings = `${animationInSlowTime}ms ${cubicBezier}`;
export const animationInFastTimings = `${animationInFastTime}ms ${cubicBezier}`;
export const animationOutTimings = `${animationOutTime}ms ${cubicBezier}`;
export const animationOutSlowTimings = `${animationOutSlowTime}ms ${cubicBezier}`;
export const animationOutFastTimings = `${animationOutFastTime}ms ${cubicBezier}`;
