<section class="non-selectable">
  <div class="section__title">
    <h2 class="section__title__headline">Pricing</h2>
    <p class="section__title__description">Affordable&nbsp;plans</p>
  </div>

  <div class="cards">
    <div class="card free">
      <div class="card__header">
        <div class="card__header__icon">
          <img ngSrc="assets/img/app/price-tag.svg" fill />
        </div>
        <h3 class="card__header__title">Basic&nbsp;Plan</h3>
        <p class="card__header__description">Essential features at no expense</p>
        <span class="card__header__price">Free</span>
      </div>

      <div class="card__features">
        <ul>
          <li>
            <span class="card__features__tick">
              <svg width="12" height="8" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.56946 5.77543L4.67267 8.87864L12.4307 1.12061"
                  stroke="#F9FAFB"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span>
            QR Code mobile application
          </li>
          <li>
            <span class="card__features__tick">
              <svg width="12" height="8" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.56946 5.77543L4.67267 8.87864L12.4307 1.12061"
                  stroke="#F9FAFB"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span>
            POS System
          </li>
          <li>
            <span class="card__features__tick">
              <svg width="12" height="8" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.56946 5.77543L4.67267 8.87864L12.4307 1.12061"
                  stroke="#F9FAFB"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span>
            Restaurant landing page
          </li>
          <li>
            <span class="card__features__tick">
              <svg width="12" height="8" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.56946 5.77543L4.67267 8.87864L12.4307 1.12061"
                  stroke="#F9FAFB"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span>
            Support
          </li>
        </ul>
      </div>

      <div class="card__cta">
        <button class="cta__button" nz-button nzType="primary">Get&nbsp;started</button>
      </div>
    </div>

    <div class="card premium">
      <div class="card__header">
        <div class="card__header__icon">
          <img ngSrc="assets/img/app/premium.svg" fill />
        </div>
        <h3 class="card__header__title">Premium&nbsp;Plan</h3>
        <p class="card__header__description">Unlock premium benefits for exceptional dining experiences</p>
        <span class="card__header__price">49€/mo</span>
      </div>

      <div class="card__features">
        <ul>
          <li>
            <span class="card__features__tick">
              <svg width="12" height="8" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.56946 5.77543L4.67267 8.87864L12.4307 1.12061"
                  stroke="#A37052"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span>
            Everything on Basic Plan
          </li>
          <li>
            <span class="card__features__tick">
              <svg width="12" height="8" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.56946 5.77543L4.67267 8.87864L12.4307 1.12061"
                  stroke="#A37052"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span>
            Analytics
          </li>
          <li>
            <span class="card__features__tick">
              <svg width="12" height="8" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.56946 5.77543L4.67267 8.87864L12.4307 1.12061"
                  stroke="#A37052"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span>
            Table reservations
          </li>
          <li>
            <span class="card__features__tick">
              <svg width="12" height="8" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.56946 5.77543L4.67267 8.87864L12.4307 1.12061"
                  stroke="#A37052"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span>
            Card payments
          </li>
          <li>
            <span class="card__features__tick">
              <svg width="12" height="8" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.56946 5.77543L4.67267 8.87864L12.4307 1.12061"
                  stroke="#A37052"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span>
            Client's preferences
          </li>
          <li>
            <span class="card__features__tick">
              <svg width="12" height="8" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.56946 5.77543L4.67267 8.87864L12.4307 1.12061"
                  stroke="#A37052"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span>
            Delivery & take-away orders
          </li>
          <li>
            <span class="card__features__tick">
              <svg width="12" height="8" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.56946 5.77543L4.67267 8.87864L12.4307 1.12061"
                  stroke="#A37052"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span>
            Waytr social media marketing
          </li>
          <li>
            <span class="card__features__tick">
              <svg width="12" height="8" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M1.56946 5.77543L4.67267 8.87864L12.4307 1.12061"
                  stroke="#A37052"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round" />
              </svg>
            </span>
            Customizations
          </li>
        </ul>
      </div>

      <div class="card__cta">
        <button class="cta__button" nz-button nzType="primary">Get&nbsp;started</button>
      </div>
    </div>

    <img class="cards__elements" ngSrc="assets/img/app/pricing-elements.webp" fill />
  </div>
</section>
