// import the required animation functions from the angular animations module
import { animate, query, style, transition, trigger } from '@angular/animations';
import { animationInTimings, animationOutTimings } from '../constants/animations';
export const routerAnimation = trigger('routerAnimation', [
  transition('* <=> *', [
    query(
      ':enter',
      [
        style({ transform: 'translateX(-20px)', opacity: 0 }),
        animate(animationInTimings, style({ transform: 'translateX(0px)', opacity: 1 })),
      ],
      { optional: true },
    ),
    query(
      ':leave',
      [
        style({ transform: 'translateX(0px)', opacity: 1 }),
        animate(animationOutTimings, style({ transform: 'translateX(-20px)', opacity: 0 })),
      ],
      { optional: true },
    ),
  ]),
]);
