import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { addAnimation } from 'src/app/animations/add.animation';
import { fadeAnimation } from 'src/app/animations/fade.animation';
import { removeAnimation } from 'src/app/animations/remove.animation';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgOptimizedImage,
    NzButtonModule,
    NzInputModule,
    NzFormModule,
    NzIconModule,
    RouterModule,
    CommonModule,
    FormsModule,
    RouterModule,
  ],
  providers: [NzNotificationService],
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  animations: [addAnimation, removeAnimation, fadeAnimation],
})
export class FooterComponent {
  constructor() {}
}
