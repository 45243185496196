<div class="gradient non-selectable"></div>
<img class="background__image" ngSrc="assets/img/app/accent-mesh.webp" fill priority />
<section class="hero non-selectable" id="home">
  <div class="hero__headline">
    <h2 class="hero__headline__title">
      Innovative POS System
      <br />
      & QR Code Menu
    </h2>
    <p class="hero__headline__subtitle">Enhance efficiency, reduce costs and elevate customer experience</p>
    <div class="hero__headline__cta">
      <nz-input-group nzPrefixIcon="mail" nzType="tag">
        <input type="text" nz-input required placeholder="Input your mail" />
      </nz-input-group>
      <button class="cta__button" nz-button nzType="primary">Subscribe</button>
    </div>
  </div>
  <div class="hero__image">
    <img class="mockup" ngSrc="assets/img/app/phone-mockup.webp" fill priority />

    <div class="qr-code">
      <img ngSrc="assets/img/app/qr-foreground.webp" fill priority />
    </div>
  </div>

  <div class="elements">
    <img class="fruit" ngSrc="assets/img/app/fruit.webp" width="180" height="140" />
    <img class="leaf" ngSrc="assets/img/app/leaf.webp" width="340" height="340" />
    <img class="sugar" ngSrc="assets/img/app/sugar.webp" width="500" height="500" priority />
  </div>
</section>
