<div class="transition">
  <svg class="transition__upper" viewBox="0 0 1920 452" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.9">
      <g filter="url(#filter0_i_1972_3680)">
        <path
          d="M450 61.006C293.884 -0.951938 0 1.00693 0 1.00693V451H1920V181.004C1609.5 304.002 1310.15 122.963 1040 101.005C769.849 79.0475 656.034 142.775 450 61.006Z"
          fill="url(#paint0_linear_1972_3680)" />
      </g>
      <path
        d="M450 61.006C293.884 -0.951938 0 1.00693 0 1.00693V451H1920V181.004C1609.5 304.002 1310.15 122.963 1040 101.005C769.849 79.0475 656.034 142.775 450 61.006Z"
        stroke="#F9FAFB" />
    </g>
    <defs>
      <filter
        id="filter0_i_1972_3680"
        x="-0.5"
        y="0.5"
        width="1937"
        height="467"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="16" dy="16" />
        <feGaussianBlur stdDeviation="32" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.976471 0 0 0 0 0.980392 0 0 0 0 0.984314 0 0 0 0.6 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1972_3680" />
      </filter>
      <linearGradient id="paint0_linear_1972_3680" x1="32.1021" y1="-85.9658" x2="1548.48" y2="370.363" gradientUnits="userSpaceOnUse">
        <stop stop-color="#EAEDF0" stop-opacity="0.87" />
        <stop offset="1" stop-color="#EAEDF0" />
      </linearGradient>
    </defs>
  </svg>

  <svg class="transition__lower" viewBox="0 0 1920 700" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_i_1972_3681)">
      <path
        d="M500 31.2865C328.959 -10.2981 286 1.56587 0 1.56587V700H1920V100.635C1557 204.161 1366.45 94.6707 1100 61.007C830.858 27.0029 703.739 80.8209 500 31.2865Z"
        fill="url(#paint0_linear_1972_3681)" />
    </g>
    <defs>
      <filter
        id="filter0_i_1972_3681"
        x="0"
        y="0"
        width="1944"
        height="724"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dx="24" dy="24" />
        <feGaussianBlur stdDeviation="32" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_1972_3681" />
      </filter>
      <linearGradient id="paint0_linear_1972_3681" x1="994.5" y1="17.4169" x2="994.5" y2="477.096" gradientUnits="userSpaceOnUse">
        <stop stop-color="#F9FAFB" />
        <stop offset="1" stop-color="#F9FAFB" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</div>

<section class="non-selectable">
  <div class="section__title">
    <h2 class="section__title__headline">Our&nbsp;solution</h2>
    <p class="section__title__description">How&nbsp;it&nbsp;works</p>
  </div>

  <div class="solution">
    <div class="solution__headline">
      <h2 class="solution__headline__title">
        Redefined POS Experience
        <br />
        for your
        <span class="solution__headline__title__bold">Fast-Paced&nbsp;Venue</span>
      </h2>
      <div class="solution__features">
        <ul>
          <li>
            <div class="icon">
              <img ngSrc="assets/img/app/notification.svg" fill />
            </div>
            <span>Get notifications of real-time ordering</span>
          </li>
          <li>
            <div class="icon">
              <img ngSrc="assets/img/app/stats.svg" fill />
            </div>
            <span>Statistics regarding most popular dishes</span>
          </li>
          <li>
            <div class="icon">
              <img ngSrc="assets/img/app/idea.svg" fill />
            </div>
            <span>Know your customers with ordering insights</span>
          </li>
        </ul>
      </div>
      <div class="solution__headline__cta">
        <button>
          <span nz-icon nzType="play-circle"></span>
          See&nbsp;how&nbsp;it&nbsp;works
        </button>
      </div>
    </div>
    <div class="solution__image">
      <img class="background__image" ngSrc="assets/img/app/pos.webp" fill />
    </div>
  </div>

  <div class="solution--reverse">
    <div class="solution__headline">
      <h3 class="solution__headline__title">
        Easy to use Ordering
        <br />
        Application for your
        <span class="solution__headline__title__bold">Clients</span>
      </h3>
      <div class="solution__features">
        <ul>
          <li>
            <div class="icon">
              <img ngSrc="assets/img/app/card.svg" fill />
            </div>
            <span>Effortlessly pay with credit card</span>
          </li>
          <li>
            <div class="icon">
              <img ngSrc="assets/img/app/star.svg" fill />
            </div>
            <span>Provide food & venue reviews</span>
          </li>
          <li>
            <div class="icon">
              <img ngSrc="assets/img/app/tie.svg" fill />
            </div>
            <span>Instantly send orders & requests to waiters</span>
          </li>
        </ul>
      </div>
      <div class="solution__headline__cta">
        <button>
          <span nz-icon nzType="play-circle"></span>
          Demonstration
        </button>
      </div>
    </div>
    <div class="solution__image">
      <img class="background__image" ngSrc="assets/img/app/phone-mockup2.webp" fill />
    </div>
  </div>
</section>
