<app-menu></app-menu>
<app-home id="home"></app-home>

<div class="pages">
  <img class="pages__background" ngSrc="assets/img/app/white-mesh.webp" fill />
  <app-solutions id="solutions"></app-solutions>
  <app-features id="features"></app-features>
</div>

<div class="footer">
  <app-pricing id="pricing"></app-pricing>
  <app-footer id="contact"></app-footer>
</div>

<img class="grain non-selectable" />
